import React from "react"
import Slider from "react-slick"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteRight, faQuoteLeft } from "@fortawesome/free-solid-svg-icons"
import { useIntl } from "gatsby-plugin-intl"

const MySlider = () => {
  const intl = useIntl()

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 7000,
    draggable: false,
    appendDots: dots => <ol> {dots} </ol>,
    customPaging: i => <button>{i + 1}</button>,
  }

  return (
    <section id="testimonials">
      <div className="row content">
        <span>
          <i className="quote-left fa">
            <FontAwesomeIcon icon={faQuoteLeft} />
          </i>
        </span>
        <div className="text-container">
          <div className="twelve columns flex-container">
            <Slider {...settings} style={{ userSelect: "text" }}>
              <div>
                <blockquote>
                  <p>{intl.formatMessage({ id: "testimonials.barrigona.text" })}</p>
                  <cite>{intl.formatMessage({ id: "testimonials.barrigona.from" })}</cite>
                </blockquote>
              </div>
              <div>
                <blockquote>
                  <p>{intl.formatMessage({ id: "testimonials.zfm.text" })}</p>
                  <cite>{intl.formatMessage({ id: "testimonials.zfm.from" })}</cite>
                </blockquote>
              </div>
              <div>
                <blockquote>
                  <p>{intl.formatMessage({ id: "testimonials.abc.text" })}</p>
                  <cite>{intl.formatMessage({ id: "testimonials.abc.from" })}</cite>
                </blockquote>
              </div>
            </Slider>
          </div>
        </div>
        <span>
          <i className="quote-right fa">
            <FontAwesomeIcon icon={faQuoteRight} />
          </i>
        </span>
      </div>
    </section>
  )
}

export default MySlider
