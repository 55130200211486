import React from "react"

import Layout from "../components/Layout"

import Hero from "../components/Hero"
import Services from "../components/Services"
import Partners from "../components/Partners"
import Testimonials from "../components/Testimonials"
import Subscribe from "../components/Subscribe"

import { useSiteMetadata } from "../hooks/use-site-metadata"

export default () => {
  const { sections } = useSiteMetadata()
  const availableSections = {
    hero: Hero,
    testimonials: Testimonials,
    services: Services,
    partners: Partners,
    subscribe: Subscribe,
  }

  return (
    <div>
      <Layout>
        {sections.map((section, idx) => {
          let Tagname = availableSections[section]
          return <Tagname key={idx.toString()} />
        })}
      </Layout>
    </div>
  )
}
