import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { misc } from "../assets/assets"
import Language from "./Language"

import NavMenu from "./Navmenu"

export default () => {
  const { logo } = misc
  return (
    <header>
      <div className="logo">
        <AnchorLink to="/#top">
          <img width="50%" alt="" src={logo} />
        </AnchorLink>
      </div>

      <NavMenu />

      <div className="language-container">
        <Language />
      </div>
    </header>
  )
}
