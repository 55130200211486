import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

const Language = () => {
  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => (
        <select
          className="language-select"
          onChange={e => {
            changeLocale(e.target.value)
          }}
        >
          <option selected={currentLocale === "es"} value="es">
            ES
          </option>
          <option selected={currentLocale === "en"} value="en">
            EN
          </option>
        </select>
      )}
    </IntlContextConsumer>
  )
}

export default Language
