import React, { Component } from "react"
import "react-image-lightbox/style.css" // This only needs to be imported once in your app
import { FormattedMessage } from "gatsby-plugin-intl"

import { partners } from "../assets/assets"

const {
  avigilon,
  bosch,
  dahua,
  fluke,
  dermalog,
  hagroy,
  tecnovision,
  hikvision,
  intrade_abc,
  paradox_security,
  prisma,
  sid,
  motorola,
} = partners

const partnersList = [
  {
    thumb: avigilon,
    url: "https://www.avigilon.com/about/avigilon",
    type: "brand",
    name: "avigilon",
  },
  {
    thumb: bosch,
    url: "https://www.boschsecurity.com",
    type: "brand",
    name: "bosch",
  },
  {
    thumb: dahua,
    url: "https://www.dahuasecurity.com",
    type: "brand",
    name: "dahua",
  },
  {
    thumb: dermalog,
    url: "https://www.dermalog.com",
    type: "brand",
    name: "dermalog",
  },
  {
    thumb: fluke,
    url: "https://www.fluke.com",
    type: "brand",
    name: "fluke",
  },
  {
    thumb: hagroy,
    url: "http://hagroy.com",
    type: "brand",
    name: "hagroy",
  },
  {
    thumb: hikvision,
    url: "https://www.hikvision.com",
    type: "brand",
    name: "hikvision",
  },
  {
    thumb: intrade_abc,
    url: "https://www.intradeabc.com",
    type: "partner",
    name: "intrade_abc",
  },
  {
    thumb: paradox_security,
    url: "https://www.paradox.com",
    type: "brand",
    name: "paradox_security",
  },
  {
    thumb: prisma,
    url: "https://www.paloaltonetworks.com/prisma/cloud",
    type: "partner",
    name: "prisma",
  },
  {
    thumb: tecnovision,
    url: "https://www.tecnovisioncctv.com",
    type: "partner",
    name: "tecnovision",
  },
  {
    thumb: sid,
    url: "https://www.sidcr.com",
    type: "partner",
    name: "sid",
  },
  {
    thumb: motorola,
    url: "https://www.motorolasolutions.com/",
    type: "brand",
    name: "motorola",
  },
]

export default class LightboxExample extends Component {
  constructor(props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }

  
  render() {

    return (
      <section id="partners">
        <div className="row">
          <div className="twelve columns">
            <div
              id="partners-wrapper"
              className="bgrid-quarters s-bgrid-thirds cf"
            >
              <div className="row section-head">
                <h1>
                  <FormattedMessage id={"partners.titleBrands"} />
                </h1>
                <p>
                  <FormattedMessage id={"partners.bodyBrands"} />
                </p>
              </div>
              {partnersList.map((item, idx) => {
                return item.type === "brand" ? (
                  <div className="columns item" key={idx.toString()}>
                    <div className="item-wrap">
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        data-imagelightbox="a"
                      >
                        <div className="frame">
                          <span className="helper">
                            <img src={item.thumb} alt={item.name} />
                          </span>
                        </div>
                        <div className="overlay">
                          <p><FormattedMessage id={`partners.descriptions.${item.name}`} /></p>
                        </div>
                      </a>
                    </div>
                  </div>
                ) : null
              })}
              <div className="clear"></div>
              <div className="row section-head">
                <h1>
                  <FormattedMessage id={"partners.titlePartners"} />
                </h1>
                <p>
                  <FormattedMessage id={"partners.bodyPartners"} />
                </p>
              </div>
              {partnersList.map((item, idx) => {
                return item.type === "partner" ? (
                  <div className="columns item" key={idx.toString()}>
                    <div className="item-wrap">
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        data-imagelightbox="a"
                      >
                        <div className="frame">
                          <span className="helper">
                            <img src={item.thumb} alt={item.name} />
                          </span>
                        </div>
                        <div className="overlay">
                          <p><FormattedMessage id={`partners.descriptions.${item.name}`} /></p>
                        </div>
                      </a>
                    </div>
                  </div>
                ) : null
              })}
            </div>
          </div>
        </div>
      </section>
    )
  }
}
