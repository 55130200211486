import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { serviceImages } from "../assets/assets"
import { useIntl } from "gatsby-plugin-intl"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "../styles/react-tabs.css"

const {
  training,
  custody,
  evaluation,
  investigation,
  securitytesting,
  securityservice,
  personallisten,
  monitoring,
  cctv,
  gps,
  fence,
  led,
  fire,
  drone,
  bodycam,
  facial,
  polygraph,
} = serviceImages

const services = {
  physical: [
    { url: training, name: "training", bullets: [1, 2, 3, 4, 5, 6, 7, 8] },
    { url: custody, name: "custody" },
    { url: evaluation, name: "evaluation" },
    { url: investigation, name: "investigation" },
    {
      url: securitytesting,
      name: "securitytesting",
      bullets: [1, 2, 3, 4],
    },
    {
      url: securityservice,
      name: "securityservice",
    },
    { url: personallisten, name: "personallisten" },
    { url: polygraph, name: "polygraph" },
  ],
  electronic: [
    { url: monitoring, name: "monitoring" },
    { url: cctv, name: "cctv" },
    { url: gps, name: "gps" },
    { url: fence, name: "fence" },
    { url: led, name: "led" },
    { url: fire, name: "fire" },
    { url: drone, name: "drone" },
    { url: bodycam, name: "bodycam" },
    { url: facial, name: "facial" },
  ],
}

export default () => {
  const { electronic, physical } = services
  const intl = useIntl()

  return (
    <section id="services">
      <p className="heading">
        {intl.formatMessage({ id: "services.heading" })}
      </p>
      <Tabs>
        <TabList>
          <Tab>{intl.formatMessage({ id: "services.physicalTab" })}</Tab>
          <Tab>{intl.formatMessage({ id: "services.electronicTab" })}</Tab>
        </TabList>

        <TabPanel>
          <div>
            {physical.map((service, index) => {
              let pos, imagepos
              if (index % 2 === 0) {
                pos = "left"
                imagepos = "right"
              } else {
                pos = "right"
                imagepos = "left"
              }
              return (
                <div className="row feature" key={index.toString()}>
                  <div className={"six columns " + pos}>
                    <h3>
                      {intl.formatMessage({
                        id: `services.physical.${service.name}.title`,
                      })}
                    </h3>
                    <p>
                      {intl.formatMessage({
                        id: `services.physical.${service.name}.body`,
                      })}
                    </p>
                    {service.bullets && service.bullets.length ? (
                      <ul>
                        {service.bullets.map((bullet, idx) => (
                          <li key={idx.toString()}>
                            {intl.formatMessage({
                              id: `services.physical.${service.name}.${bullet}`,
                            })}
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </div>

                  <ScrollAnimation
                    animateIn="pulse"
                    animateOnce={true}
                    initiallyVisible={true}
                    className={"six columns feature-media " + imagepos}
                  >
                    <img src={service.url} alt="" />
                  </ScrollAnimation>
                </div>
              )
            })}
          </div>
        </TabPanel>
        <TabPanel>
          {electronic.map((service, index) => {
            let pos, imagepos
            if (index % 2 === 0) {
              pos = "right"
              imagepos = "left"
            } else {
              pos = "left"
              imagepos = "right"
            }
            return (
              <div className="row feature" key={index.toString()}>
                <div className={"six columns " + pos}>
                  <h3>
                    {intl.formatMessage({
                      id: `services.electronic.${service.name}.title`,
                    })}
                  </h3>
                  <p>
                    {intl.formatMessage({
                      id: `services.electronic.${service.name}.body`,
                    })}
                  </p>
                </div>

                <ScrollAnimation
                  animateIn="pulse"
                  animateOnce={true}
                  initiallyVisible={true}
                  className={"six columns feature-media " + imagepos}
                >
                  <img src={service.url} alt="" />
                </ScrollAnimation>
              </div>
            )
          })}
        </TabPanel>
      </Tabs>
    </section>
  )
}
