import React from "react"
//import { useSiteMetadata } from "../hooks/use-site-metadata"
import { useIntl } from "gatsby-plugin-intl"
import { videos } from "../assets/assets"

export default () => {
  const intl = useIntl()

  const { heroVideo } = videos

  return (
    <section id="hero">
      <div className="video-container">
        <video autoPlay muted loop playsInline id="video">
          <source src={heroVideo} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
        <div className="content">
          <div className="row">
            <div className="twelve columns">
              <div className="hero-text">
                <h1 className="responsive-headline">
                  {intl.formatMessage({ id: "heroHeadline" })}
                </h1>
                <p>{intl.formatMessage({ id: "heroBody" })}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
