import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { useIntl } from "gatsby-plugin-intl"
import { useInput } from "../hooks/input"
import { init, send } from "emailjs-com"

init("user_Etq409Pj0YqsQ92fliUyg")

export default () => {
  const intl = useIntl()
  const { value: from, bind: bindFrom, reset: resetFrom } = useInput("")
  const { value: message, bind: bindMessage, reset: resetMessage } = useInput(
    ""
  )

  const handleSubmit = e => {
    e.preventDefault()
    const templateParams = {
      from,
      message,
    }
    send("service_9bhz522", "template_zhojnbp", templateParams)
    resetFrom()
    resetMessage()
    alert(intl.formatMessage({ id: "emailSent" }))
  }

  return (
    <section id="subscribe">
      <div className="row section-head">
        <div className="twelve columns">
          <h1>{intl.formatMessage({ id: "subscribe.title" })}</h1>
          <p>{intl.formatMessage({ id: "subscribe.body" })}</p>
        </div>
      </div>

      <div className="row">
        <div className="twelve columns">
          <div id="mc_embed_signup">
            <form
              onSubmit={handleSubmit}
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_blank"
            >
              <ScrollAnimation animateIn="fadeInLeftBig" animateOnce={true}>
                <input
                  type="email"
                  name="from"
                  className="email"
                  id="mce-EMAIL"
                  placeholder={intl.formatMessage({ id: "subscribe.email" })}
                  required
                  {...bindFrom}
                />
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInRightBig" animateOnce={true}>
                <input
                  type="submit"
                  value={intl.formatMessage({ id: "subscribe.submit" })}
                  name="submit"
                  id="mc-embedded-subscribe"
                  className="button"
                />
              </ScrollAnimation>
              <textarea
                type="textarea"
                rows="5"
                name="message"
                id="mc-embedded-body"
                placeholder={intl.formatMessage({ id: "subscribe.message" })}
                required
                {...bindMessage}
              />
              {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
              <div style={{ position: "absolute", left: "-5000px" }}>
                <input
                  type="text"
                  name="b_cdb7b577e41181934ed6a6a44_e65110b38d"
                  defaultValue=""
                />
              </div>
            </form>
          </div>
          <p>
            <small>{intl.formatMessage({ id: "subscribe.small" })}</small>
          </p>
        </div>
      </div>
    </section>
  )
}
