const partners = {
  avigilon: require("./images/partners/avigilon.png"),
  bosch: require("./images/partners/bosch.png"),
  dahua: require("./images/partners/dahua.png"),
  fluke: require("./images/partners/fluke.png"),
  dermalog: require("./images/partners/dermalog.png"),
  hagroy: require("./images/partners/hagroy.png"),
  tecnovision: require("./images/partners/tecnovision.png"),
  hikvision: require("./images/partners/hikvision.png"),
  intrade_abc: require("./images/partners/intrade_abc.png"),
  paradox_security: require("./images/partners/paradox_security.png"),
  safecount: require("./images/partners/safecount.png"),
  prisma: require("./images/partners/prisma.png"),
  sid: require("./images/partners/sid.png"),
  motorola: require("./images/partners/motorola.png"),
}
const videos = { heroVideo: require("./videos/global.mp4") }
const misc = {
  logo: require("./images/logo.png"),
  footer_logo: require("./images/logo.png"),
  cert: require("./images/logobasc.png"),
}
const serviceImages = {
  training: require("./images/services/training.jpg"),
  custody: require("./images/services/custody.jpg"),
  evaluation: require("./images/services/evaluation.jpg"),
  investigation: require("./images/services/investigation.jpg"),
  securitytesting: require("./images/services/securitytesting.jpg"),
  securityservice: require("./images/services/securityservice.jpg"),
  personallisten: require("./images/services/personallisten.jpg"),
  monitoring: require("./images/services/monitoring.jpg"),
  cctv: require("./images/services/cctv.jpg"),
  gps: require("./images/services/gps.jpg"),
  fence: require("./images/services/fence.jpg"),
  led: require("./images/services/led.jpg"),
  fire: require("./images/services/fire.jpg"),
  drone: require("./images/services/drone.jpg"),
  bodycam: require("./images/services/bodycam.jpg"),
  facial: require("./images/services/facial.jpg"),
  polygraph: require("./images/services/polygraph.jpg"),
}
export { partners, videos, misc, serviceImages }
