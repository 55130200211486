import React, { useState } from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import Scrollspy from "react-scrollspy"

import { useSiteMetadata } from "../hooks/use-site-metadata"
import { useIntl } from "gatsby-plugin-intl"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

export default function () {
  const [isVisible, setVisibility] = useState(false)

  const { menuItems } = useSiteMetadata()
  const intl = useIntl()

  let showStyle = null
  let navStyle = null
  if (isVisible){
    showStyle = {transform: 'scaleY(1)'}
    navStyle = { maxHeight: "unset" }
  }else{
    showStyle = {height: 0, padding: 0}
    navStyle = { maxHeight: "55px" }
  }

  return (
    <nav id="nav-wrap" style={navStyle}>
      <button
        id="toggle-btn"
        href="/#"
        title="Menu"
        onClick={() => setVisibility(!isVisible)}
      >
        <FontAwesomeIcon icon={faBars} />
      </button>

      <Scrollspy
        className="nav mobile"
        style={showStyle}
        items={menuItems.map(a => a.path)}
        currentClassName="current"
        offset={-100}
      >
        {menuItems.map((value, index) => {
          return (
            <li key={index.toString()}>
              <button
                onClick={() => {
                  scrollTo("#" + value.path)
                  setVisibility(0)
                }}
              >
                {intl.formatMessage({ id: "menuItems." + value.path })}
              </button>
            </li>
          )
        })}
      </Scrollspy>
    </nav>
  )
}
