import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import scrollTo from "gatsby-plugin-smoothscroll"
import { useIntl } from "gatsby-plugin-intl"
import { misc } from "../assets/assets"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronUp,
  faMapMarkerAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons"

export default () => {
  const { address, contacts } = useSiteMetadata()
  const intl = useIntl()
  const { footer_logo, cert } = misc

  return (
    <footer>
      <div className="row">
        <div className="eight columns info">
          <div className="footer-logo">
            <img src={footer_logo} alt="" />
            <a
              className="cert"
              target="_blank"
              rel="noopener noreferrer"
              title="BASC certificate"
              href="https://www.wbasco.org/es"
            >
              <img src={cert} alt="BASC certificate" />
            </a>
          </div>

          <p>{intl.formatMessage({ id: "footer.us" })}</p>
        </div>

        <div className="four columns right-cols">
          <div className="row">
            <div className="columns address">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <p>
                {intl.formatMessage({ id: "footer.address" })}
                <br />
                {address.line2}
                <br />
                {address.line3}
              </p>
            </div>

            <div className="columns last">
              <FontAwesomeIcon icon={faEnvelope} />
              <h3 className="contact">
                {intl.formatMessage({ id: "footer.contact" })}
              </h3>
              <ul>
                {contacts.map((contact, idx) => (
                  <li key={idx.toString()}>
                    <a href={contact.url}>{contact.text}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <p className="copyright">&copy; 2021 CincoCinco Films</p>

        <div id="go-top">
          <button title="Back to Top" onClick={() => scrollTo("#top")}>
            <FontAwesomeIcon icon={faChevronUp} />
          </button>
        </div>
      </div>
    </footer>
  )
}
