import React from "react"
import { Helmet } from "react-helmet"
import WhatsAppWidget from "react-whatsapp-widget"
import "react-whatsapp-widget/dist/index.css"

import { useSiteMetadata } from "../hooks/use-site-metadata"
import { useIntl } from "gatsby-plugin-intl"

import Header from "./Header"
import Footer from "./Footer"

import "../styles/default.css"
import "../styles/layout.css"
import "../styles/media-queries.css"
import "../styles/animate.css"

import "typeface-open-sans"
import "typeface-montserrat"

import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
config.autoAddCss = false

export default function Layout({ children }) {
  const { title, author, favicon } = useSiteMetadata()
  const intl = useIntl()

  return (
    <div>
      <Helmet htmlAttributes={{ lang: intl.formatMessage({ id: "language" }) }}>
        <title>{title}</title>
        <meta name="author" content={author.name} />
        <meta
          name="description"
          content={intl.formatMessage({ id: "meta.description" })}
        />
        <meta
          name="keywords"
          content={intl.formatMessage({ id: "meta.keywords" })}
        />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />

        <link rel="shortcut icon" href={"/" + favicon} type="image/png" />
      </Helmet>

      <Header />

      <main id="top">{children}</main>

      <Footer />

      <div className="wa-btn">
        <WhatsAppWidget
          phoneNumber="50663958728"
          message={intl.formatMessage({ id: "whatsapp.message" })}
          textReplyTime={intl.formatMessage({ id: "whatsapp.replyTime" })}
          sendButton={intl.formatMessage({ id: "whatsapp.send" })}
          placeholder={intl.formatMessage({ id: "whatsapp.placeholder" })}
          companyName="Seguridad y Protección Global"
        />
      </div>
    </div>
  )
}
