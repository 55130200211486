import { useStaticQuery, graphql } from "gatsby"
export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query MyQuery {
        site {
          siteMetadata {
            title
            author {
              name
            }
            sections
            favicon
            logo
            menuItems {
              path
              label
            }
            social {
              service
              url
              fa
            }
            footer {
              logo
              cert
            }
            address {
              line1
              line2
            }
            contacts {
              text
              url
            }
            heroVideo
            services {
              physical {
                name
                url
              }
              electronic {
                name
                url
              }
            }
          }
        }
      }
    `
  )
  return site.siteMetadata
}
